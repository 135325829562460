<template>
  <div>
    <ion-list lines="none" :class="{ 'h-100': listQuotation.data.length === 0 }">
      <ion-item
        class="ion-no-padding"
        v-for="(quotation, index) in listQuotation.data || []"
        :key="index"
        @click="$emit('openQuotationDetail', quotation.id)"
      >
        <ion-grid>
          <div class="d-flex ion-padding-start mt-1">
            <ion-text class="ion-no-padding ml-1 fs-1">{{ coverTime(quotation.created_at) }}</ion-text>
          </div>
          <ion-row>
            <ion-col size="3">
              <div>
                <td-image
                  style="width: 66px; height: auto"
                  alt="product"
                  :image="quotation.image"
                  :imagePath="IMAGE_PATH.PRODUCT"
                  :imageDefault="DEFAULT_PRODUCT_IMAGE"
                ></td-image>
              </div>
            </ion-col>
            <ion-col size="9" class="d-flex align-center">
              <ion-label>
                <div class="fs-3 mb-1">{{ quotation.sku_name }}</div>
                <div>
                  <ion-avatar style="height: 100%" v-if="quotation.halal">
                    <ion-img style="width: 24px; height: 24px" src="/assets/images/halal.svg" alt="img" />
                  </ion-avatar>
                </div>
              </ion-label>
            </ion-col>
          </ion-row>
          <div class="d-flex ion-padding-start justify-space-between mt-1 mb-1">
            <div>
              <div style="font-size: 12px">{{ $t('quotation_price') }}</div>
              <div class="text-primary fs-2 fw-bold">
                {{
                  quotation.price && quotation.status
                    ? `${priceFormatter(currencySymbol, displayPrice(quotation.price))}`
                    : '-'
                }}
              </div>
            </div>
            <div class="ion-text-end">
              <span class="process-item">
                <ion-button
                  class="status"
                  size="small"
                  :color="
                    quotation.quotation_status === 'rejected'
                      ? 'danger'
                      : quotation.quotation_status === 'requested'
                      ? 'light'
                      : 'primary'
                  "
                >
                  <span class="mr-2 ml-1 ion-text-capitalize">{{ quotation.quotation_status }}</span>
                  <ion-icon :icon="changeIcon(quotation.quotation_status)"></ion-icon>
                </ion-button>
              </span>
            </div>
          </div>
        </ion-grid>
      </ion-item>

      <ion-grid style="height: 100%" v-if="listQuotation.data.length === 0">
        <ion-row class="justify-center align-center" style="height: 100%">
          <div class="text-center">
            <div class="fs-4 fw-500">{{ $t('noData') }}</div>
          </div>
        </ion-row>
      </ion-grid>
    </ion-list>

    <ion-infinite-scroll
      @ionInfinite="loadMoreData"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisableLoadMore"
    >
      <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>

<script>
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants/index';
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { priceFormatter } from '@/utils/';
import { checkmarkCircleOutline, closeOutline, paperPlaneOutline, repeatOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('b2b/notification');

export default defineComponent({
  name: 'Quotations',
  props: {
    isDisableLoadMore: {
      type: Boolean,
      default: false
    },
    currencySymbol: {
      type: String,
      default: 'S$'
    }
  },
  setup() {
    return {
      checkmarkCircleOutline,
      paperPlaneOutline,
      closeOutline,
      repeatOutline,
      displayPrice,
      priceFormatter,
      DEFAULT_PRODUCT_IMAGE,
      IMAGE_PATH
    };
  },
  computed: {
    ...mapGetters(['listQuotation']),
    changeIcon() {
      return (value) => {
        if (value.toLowerCase().includes('received')) return checkmarkCircleOutline;
        else if (value.toLowerCase().includes('rejected')) return closeOutline;
        else if (value.toLowerCase().includes('requested')) return paperPlaneOutline;
        else if (value.toLowerCase().includes('pending approval')) return repeatOutline;
        else return repeatOutline;
      };
    }
  },
  methods: {
    coverTime(params) {
      const data = `${new Date(params)}`;
      const time = data.slice(8, 11) + data.slice(4, 7) + data.slice(10, 16) + data.slice(16, 21);
      return time;
    },
    loadMoreData(e) {
      this.$emit('loadMoreData', e);
    }
  }
});
</script>

<style scoped lang="scss">
.product-image::part(image) {
  width: 63px;
  height: 63px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.process-item {
  width: auto;
  display: inline-block;
}
.status {
  font-size: 12px;
  --border-radius: 28px;
}
ion-row {
  display: flex;
  justify-content: center !important;
  align-content: center !important;
  height: 80%;
}
ion-img {
  width: 110px;
  height: 110px;
  margin: 0 auto;
}
ion-item {
  border-bottom: 1px solid grey;
}
ion-label {
  white-space: normal !important;
}
.title-skeleton {
  height: 16px;
}

.thumbnail {
  width: 48px;
  height: 48px;
}

.icon-halal {
  width: 22px;
  height: 22px;
}
.h-100 {
  height: 100%;
}
ion-tab-bar {
  border: none;
}
.active-tab {
  border-bottom: 2px solid var(--ion-color-primary);
}
#row {
  height: 50px;
}
ion-header {
  height: 120px;
}
</style>
