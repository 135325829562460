<template>
  <ion-list v-for="i in 10" :key="i">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col size="2">
          <ion-avatar class="thumbnail">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
        </ion-col>
        <ion-col size="9">
          <div class="title-skeleton">
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          </div>
          <div>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </div>
        </ion-col>
        <ion-col size="1">
          <ion-avatar class="icon-halal">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-list>
</template>
<script>
export default {
  name: 'SkeletonLoading'
};
</script>
<style lang="scss" scoped>
.title-skeleton {
  height: 16px;
}
.icon-halal {
  width: 22px;
  height: 22px;
}
.thumbnail {
  width: 48px;
  height: 48px;
}
</style>
