<template>
  <ion-list v-for="i in 10" :key="i">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-text class="">
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col size="auto">
          <ion-thumbnail class="thumbnail">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-thumbnail>
        </ion-col>
        <ion-col>
          <div class="title">
            <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
          </div>
          <div>
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          </div>
          <div>
            <ion-avatar class="icon-halal">
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-avatar>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="ion-align-items-center">
        <ion-col>
          <div>
            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
          </div>
          <div>
            <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
          </div>
        </ion-col>
        <ion-col size="2">
          <div class="title-skeleton">
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-list>
</template>
<script>
export default {
  name: 'SkeletonLoading'
};
</script>
