<template>
  <ion-header>
    <ion-toolbar mode="ios">
      <ion-title class="fs-500 pl-0 pr-0">{{ $t('requestQuotationDetails') }}</ion-title>
      <ion-buttons slot="end" class="mr-2" @click="$emit('close-modal')">
        <ion-icon slot="icon-only" size="small" :icon="closeCircleOutline"></ion-icon>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content v-if="isShow">
    <ion-list lines="none">
      <ion-item class="pl-2 mt-2 mb-3">
        <ion-avatar slot="start" style="width: auto; height: auto">
          <td-image
            key="user"
            style="width: 48px; height: 48px"
            alt="user"
            :image="requestor?.image"
            :imagePath="IMAGE_PATH.USER"
            :imageDefault="DEFAULT_USER_AVARTAR"
          ></td-image>
        </ion-avatar>
        <ion-label>
          <h3 class="fs-3">{{ item.requestor.first_name }} {{ item.requestor.last_name || '' }}</h3>
          <div class="d-flex mt-1">
            <ion-icon :icon="homeOutline"></ion-icon>
            <span class="fs-2 ml-1 grey-color" style="margin-top: 2px">{{ item.customer_name }}</span>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>
    <div class="d-flex justify-space-between ml-2 mr-2 align-center">
      <div>
        <div class="fw-bold fs-3">{{ $t('requestAt') }}</div>
        <div class="d-flex mt-1">
          <ion-text class="ion-no-padding fs-12 grey-color">{{
            dayjs(item.created_at).format('DD MMM YYYY HH:mm')
          }}</ion-text>
        </div>
      </div>
      <div class="ion-text-end">
        <span class="process-item">
          <ion-button
            class="status"
            size="small"
            :color="
              item.quotation_status === 'rejected'
                ? 'danger'
                : item.quotation_status === 'requested'
                ? 'light'
                : 'primary'
            "
          >
            <span class="mr-2 ml-1 ion-text-capitalize">{{ item.quotation_status }}</span>
            <ion-icon :icon="changeIcon(item.quotation_status)"></ion-icon>
          </ion-button>
        </span>
      </div>
    </div>
    <ion-grid v-if="item.price != null">
      <ion-row>
        <ion-col size="6" v-if="item.quotedBy != null">
          <div class="fs-3 fw-bold mb-1">{{ $t('quotedBy') }}</div>
          <div class="fs-3">{{ item.quotedBy.first_name }} {{ item.quotedBy.last_name }}</div>
        </ion-col>
        <ion-col size="6" v-if="item.approver != null">
          <div class="fs-3 fw-bold mb-1">
            {{ item.quotation_status === 'rejected' ? $t('rejectedBy') : $t('approvedBy') }}
          </div>

          <div class="fs-3">{{ item.approver.first_name }} {{ item.approver.last_name }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-list lines="none" class="mt-3 mb-3">
      <ion-item class="ion-no-padding">
        <ion-grid>
          <ion-row class="fw-bold fs-3 mb-2 ml-1">{{ $t('productInfo') }}</ion-row>
          <ion-row>
            <ion-col size="2" class="mr-4">
              <div>
                <td-image
                  key="product"
                  style="width: 66px; height: auto"
                  alt="product"
                  :image="item?.image"
                  :imagePath="IMAGE_PATH.PRODUCT"
                  :imageDefault="DEFAULT_PRODUCT_IMAGE"
                ></td-image>
              </div>
            </ion-col>
            <ion-col size="9" class="d-flex align-center">
              <ion-label>
                <div class="fs-3 mb-1 ion-text-wrap">{{ item.sku_name }}</div>
                <div v-if="item.halal">
                  <ion-avatar style="height: 100%">
                    <ion-img style="width: 24px; height: 24px" src="/assets/images/halal.svg" alt="img" />
                  </ion-avatar>
                </div>
              </ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>

    <div class="d-flex justify-space-between ml-2 mr-3" v-if="item.price">
      <div>
        <div class="fs-3 mb-2">{{ $t('approvedPrice') }}:</div>
        <div class="fs-3">{{ $t('approvedPrice') }} / {{ item.uom }}:</div>
      </div>
      <div class="text-primary fw-bold fs-3">
        <div class="mb-2">
          {{ item.status ? `${priceFormatter(currencySymbol, displayPrice(item.price))}` : `-` }}
        </div>
        <div>
          {{ item.status ? `${priceFormatter(currencySymbol, displayPrice(item.unit_price))}` : `-` }}
        </div>
      </div>
    </div>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button
            @click="toProductDetail(item.product_id)"
            expand="block"
            color="primary"
            fill="outline"
            class="text-capitalize"
            >{{ $t('view_product') }}</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import { DEFAULT_PRODUCT_IMAGE, DEFAULT_USER_AVARTAR, IMAGE_PATH } from '@/modules/b2b/constants/index';
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { priceFormatter } from '@/utils/';
import dayjs from 'dayjs';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  closeOutline,
  homeOutline,
  paperPlaneOutline,
  repeatOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/notification');
export default defineComponent({
  name: 'order-detail',
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  emits: ['close-modal'],
  inject: ['$storage'],
  setup(_, { emit }) {
    const user = ref(null);
    const router = useRouter();
    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const image = ref(null);
    const avatarImage = ref(null);
    const toProductDetail = (productId) => {
      router.push({
        path: `/b2b/main/product-detail/${productId}`
      });
      emit('close-modal');
    };
    return {
      checkmarkCircleOutline,
      paperPlaneOutline,
      closeOutline,
      repeatOutline,
      closeCircleOutline,
      homeOutline,
      user,
      displayPrice,
      setOpenLoading,
      isOpenLoadingref,
      image,
      avatarImage,
      dayjs,
      priceFormatter,
      DEFAULT_USER_AVARTAR,
      DEFAULT_PRODUCT_IMAGE,
      IMAGE_PATH,
      isShow: ref(false),
      toProductDetail
    };
  },
  async mounted() {
    this.getData();
    this.user = await this.$storage.getUser();
  },
  computed: {
    ...mapGetters(['item', 'status']),
    changeIcon() {
      return (value) => {
        if (value.toLowerCase().includes('received')) return checkmarkCircleOutline;
        else if (value.toLowerCase().includes('rejected')) return closeOutline;
        else if (value.toLowerCase().includes('requested')) return paperPlaneOutline;
        else if (value.toLowerCase().includes('pending approval')) return repeatOutline;
        else return repeatOutline;
      };
    },
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    }
  },
  methods: {
    coverTime(params) {
      const data = `${new Date(params)}`;
      const time = data.slice(8, 11) + data.slice(4, 7) + data.slice(10, 16) + data.slice(16, 21);
      return time;
    },
    ...mapActions([ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]),
    async getData() {
      this.setOpenLoading(true);
      await this[ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]({
        id: this.id
      });
      this.setOpenLoading(false);
      if (this.status) {
        this.isShow = true;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.fs-15 {
  font-size: 15px;
  line-height: 22px;
  color: #9e9e9e;
}
.fs-11 {
  font-size: 11px;
}
.green-color {
  color: #64ae0f;
}
.grey-color {
  color: #424242;
}
.process-item {
  width: auto;
  display: inline-block;
  & > ion-button {
    pointer-events: none;
  }
}
.status {
  font-size: 12px;
  --border-radius: 28px;
}
</style>
