<template>
  <div :key="refreshTransactionScreen">
    <ion-list lines="none" :class="{ 'h-100': list.data.length === 0 }">
      <ion-item
        class="pr-2 mt-2 mb-2"
        v-for="(item, index) in list.data || []"
        :key="index"
        @click="onTapNotification(index)"
      >
        <ion-avatar slot="start">
          <td-image
            style="width: 48px; height: 48px"
            alt="user"
            :image="item?.image_sender"
            :imagePath="IMAGE_PATH.USER"
            :imageDefault="DEFAULT_USER_AVARTAR"
          ></td-image>
        </ion-avatar>
        <ion-label>
          <h3 class="fw-400 fs-3">{{ handleRemoveJunkCharacters(item.title) }}</h3>
          <div class="fs-12 pr-3 message">
            {{ item.message
            }}{{ item.message.slice(-1) === '.' || item.message.slice(-1) === '!' ? '' : '.' }}
          </div>
        </ion-label>
        <div class="ion-text-right" style="max-width: 75px">
          <div class="d-flex justify-end" v-if="!item.read">
            <div class="out-round">
              <div class="in-round" />
            </div>
          </div>
          <div style="font-size: 12px" class="d-flex" v-html="dateTimeDiff(item.created_at)"></div>
        </div>
      </ion-item>
      <ion-grid style="height: 100%" v-if="list.data.length === 0">
        <ion-row class="justify-center align-center" style="height: 100%">
          <div class="text-center">
            <ion-img src="/assets/images/alarm.png"></ion-img>
            <div class="fs-4 mt-4 mb-2">{{ $t('sorry') }}</div>
            <div class="fs-3">{{ $t('no_notification') }}</div>
          </div>
        </ion-row>
      </ion-grid>
    </ion-list>
    <ion-infinite-scroll
      @ionInfinite="loadMoreData"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisableLoadMore"
    >
      <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ion-button v-if="!list.isAllNotifyRead && list.data.length !== 0" @click="$emit('readNotifications')">{{
      $t('markAsReadAll')
    }}</ion-button>
  </div>
</template>

<script>
import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { MUTATIONS } from '@/modules/b2b/store/product/mutations';
import { DEFAULT_USER_AVARTAR, IMAGE_PATH } from '@/modules/sale/constants/index';
import { NUMBER_OF_NOTIFICATION } from '@/modules/shared/constants/';
import Clevertap from '@/services/shared/helper/clevertap';
import dayjs from 'dayjs';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapMutations: mapMutationsProduct } = createNamespacedHelpers('b2b/product');
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/notification');

export default defineComponent({
  name: 'Transactions',
  props: {
    isDisableLoadMore: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      default: 0
    },
    refreshTransactionScreen: {
      type: Number,
      default: 1
    }
  },
  setup() {
    const isOpenModalTransactionPiceUpdate = ref(false);
    const setOpenModalTransactionPiceUpdate = (state) => (isOpenModalTransactionPiceUpdate.value = state);
    const sku_id = ref(null);
    const sub_type = ref(null);
    const account_number = ref(null);
    const createdAt = ref(null);
    return {
      arrowBackOutline,
      isOpenModalTransactionPiceUpdate,
      setOpenModalTransactionPiceUpdate,
      sku_id,
      sub_type,
      createdAt,
      account_number,
      DEFAULT_USER_AVARTAR,
      IMAGE_PATH
    };
  },
  computed: {
    ...mapGetters(['list'])
  },
  methods: {
    ...mapActions([ACTIONS.UPDATE_SUB_BUYER, ACTIONS.GET_BADGE_NUMBERS]),
    ...mapMutationsProduct([MUTATIONS.CHANGE_ALL_CATEGORY]),
    handleRemoveJunkCharacters(payload) {
      /* eslint-disable */
      return payload.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    },
    dateTimeDiff(dateYMD) {
      const currentDateTime = new Date();
      let date = dayjs(dateYMD);
      let currentDate = dayjs(currentDateTime);
      let diffTime = currentDate.diff(date);
      let daysDiff = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (daysDiff >= 1) return `${daysDiff} day${daysDiff !== 1 ? 's' : ''} <br/>ago`;
      let hoursDiff = Math.floor(diffTime / (1000 * 60 * 60));
      if (hoursDiff >= 1) return `${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''} <br/>ago`;
      let minsDiff = Math.floor(diffTime / (1000 * 60));
      if (minsDiff >= 1) return `${minsDiff} min${minsDiff !== 1 ? 's' : ''} <br/>ago`;
      return 'few seconds ago';
    },
    async onTapNotification(index) {
      Clevertap.onUserPushNotification(this.list.data[index]);
      this.notificationAction(index);
      if (!this.list.data[index].read) {
        /* Set notification as read */
        await this[ACTIONS.UPDATE_SUB_BUYER]({
          notificationId: this.list.data[index].id,
          read: true
        });
        await this[ACTIONS.GET_BADGE_NUMBERS]({
          customerId: this.companyId
        });

        this.list.data[index].read = true;
      }
    },
    notificationAction(index) {
      const subType = this.list.data[index].sub_type;
      const title = this.list.data[index].title;
      const payload = JSON.parse(this.list.data[index].payload);
      if (NUMBER_OF_NOTIFICATION.NEW_PRODUCT.includes(subType) || title === 'New Product') {
        this.$router.push({ name: 'home', params: { isNewest: true } });
      } else if (NUMBER_OF_NOTIFICATION.QUOTATION.includes(subType)) {
        this.$emit('openQuotationDetail', payload.quotation_id);
      } else if (NUMBER_OF_NOTIFICATION.ORDER.includes(subType) || title.includes('Order')) {
        this.$router.push('/b2b/order/purchase');
      } else if (subType === 16 || subType === 22) {
        const payloadParent = {
          sku_id: payload.sku_id,
          sub_type: subType,
          account_number: payload.account_number,
          createdAt: this.list.data[index].created_at
        };
        this.$emit('openTrxPrice', payloadParent);
      } else if (NUMBER_OF_NOTIFICATION.ORDER_JOURNEY.includes(subType) && payload) {
        this.$router.push({ path: `/b2b/order/purchase/order-detail/${payload.order_id}` });
      }
    },
    loadMoreData(e) {
      this.$emit('loadMoreData', e);
    }
  }
});
</script>

<style scoped>
.product-image::part(image) {
  width: 63px;
  height: 63px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
ion-row {
  display: flex;
  justify-content: center !important;
  align-content: center !important;
  height: 80%;
}
ion-img {
  width: 110px;
  height: 110px;
  margin: 0 auto;
}

.title-skeleton {
  height: 16px;
}

.thumbnail {
  width: 48px;
  height: 48px;
}

.icon-halal {
  width: 22px;
  height: 22px;
}
.out-round {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #dcedc8;
  position: relative;
}
.in-round {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--ion-color-primary);
  position: absolute;
  top: 19%;
  left: 20%;
}
.h-100 {
  height: 100%;
}
.message {
  white-space: pre-wrap;
}

#row {
  height: 50px;
}
ion-button {
  position: fixed;
  z-index: 99999;
  bottom: 10px;
  --border-radius: 12px;
  color: white;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
  width: fit-content;
  text-transform: none;
}
ion-header {
  height: 120px;
}
</style>
