<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="12" class="d-flex">
              <ion-buttons class="mr-2" @click="goBack">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('notifications') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
      <ion-toolbar>
        <ion-segment mode="ios" v-model="selectedSegment">
          <ion-segment-button value="transactions">
            <ion-label>{{ $t('transaction') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="quotations">
            <ion-label>{{ $t('quotation') }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <skeleton-loading v-if="isLoading && selectedSegment === 'quotations'" />
      <skeleton-loading-trx v-else-if="isLoading && selectedSegment === 'transactions'" />
      <quotations
        v-else-if="selectedSegment === 'quotations' && !isLoading"
        @openQuotationDetail="openQuotationDetail"
        @loadMoreData="loadMoreQuotations"
        :isDisableLoadMore="isDisableLoadMore"
        :currencySymbol="user?.country?.currency_symbol ?? 'S$'"
      />
      <transactions
        v-else
        @loadMoreData="loadMoreTransactions"
        @readNotifications="readNotifications"
        @openQuotationDetail="openQuotationDetail"
        @openTrxPrice="openTrxPrice"
        :isDisableLoadMore="isDisableLoadMoreTrx"
        :companyId="user?.id"
        :refreshTransactionScreen="refreshTransactionScreen"
      />
    </ion-content>
    <ion-modal :is-open="isOpenModal" css-class="order-detail" @didDismiss="setOpenModal(false)">
      <quotations-detail @close-modal="closeQuotationDetail" :id="itemQuotation"></quotations-detail>
    </ion-modal>
    <ion-modal :is-open="isOpenModalTrx" @didDismiss="setOpenModalTrx(false)">
      <trx-price-update
        :skuId="payloadTrxPrice.sku_id"
        :subType="payloadTrxPrice.sub_type"
        :createdAt="payloadTrxPrice.createdAt"
        :accountNumber="payloadTrxPrice.account_number"
        @close-modal="setOpenModalTrx(false)"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import TrxPriceUpdate from '@/modules/shared/views/ProductPriceUpdateNotification/index.vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import QuotationsDetail from './components/QuotationDetails';
import Quotations from './components/Quotations';
import SkeletonLoading from './components/SkeletonQuotations';
import SkeletonLoadingTrx from './components/SkeletonTransactions';
import Transactions from './components/Transactions';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/notification');

export default defineComponent({
  name: 'NotificationsB2b',
  components: {
    Quotations,
    QuotationsDetail,
    Transactions,
    SkeletonLoading,
    SkeletonLoadingTrx,
    TrxPriceUpdate
  },
  inject: ['$storage'],
  setup() {
    const selectedSegment = ref('transactions');
    const setSelectedSegment = (value) => {
      selectedSegment.value = value;
    };
    const selectCompany = ref(null);
    const user = ref(null);
    const isLoading = ref(false);
    const isOpenModal = ref(false);
    const isOpenModalTrx = ref(false);
    const itemQuotation = ref(null);
    const isDisableLoadMore = ref(false);
    const isDisableLoadMoreTrx = ref(false);
    const noLoadMore = ref(false);
    const noLoadMoreTrx = ref(false);
    const refreshTransactionScreen = ref(1);
    const payloadTrxPrice = ref({});
    const setOpenModal = (value) => {
      isOpenModal.value = value;
    };
    const setOpenModalTrx = (value) => {
      isOpenModalTrx.value = value;
    };
    const setLoading = (value) => {
      isLoading.value = value;
    };
    const goBack = () => {
      window.history.back();
    };
    const closeQuotationDetail = () => {
      setOpenModal(false);
    };
    const openQuotationDetail = (id) => {
      setSelectedSegment('quotations');
      setOpenModal(true);
      itemQuotation.value = id;
    };
    return {
      arrowBackOutline,
      closeQuotationDetail,
      goBack,
      isDisableLoadMore,
      isDisableLoadMoreTrx,
      isLoading,
      isOpenModal,
      isOpenModalTrx,
      itemQuotation,
      noLoadMore,
      noLoadMoreTrx,
      openQuotationDetail,
      refreshTransactionScreen,
      selectCompany,
      selectedSegment,
      setLoading,
      setOpenModal,
      setOpenModalTrx,
      setSelectedSegment,
      user,
      payloadTrxPrice
    };
  },
  async mounted() {
    this.setLoading(true);
    this.selectCompany = await this.$storage.getSelectedCompany();
    this.user = await this.$storage.getUser();
    await this.getQuotation();
    await this.getTransaction();
    this.setLoading(false);
    if (this.$route.query.segment) this.setSelectedSegment('quotations');
    else this.setSelectedSegment('transactions');
  },
  computed: {
    ...mapGetters(['list', 'listQuotation', 'totalCount', 'totalCountQuotation', 'status'])
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_VIEWS_QUOTATIONS,
      ACTIONS.LOAD_MORE_QUOTATIONS,
      ACTIONS.GET_NOTIFICATIONS,
      ACTIONS.LOAD_MORE_NOTIFICATION,
      ACTIONS.GET_ALL_USER_NOTIFICATIONS_B2B
    ]),
    async getTransaction() {
      try {
        await this[ACTIONS.GET_NOTIFICATIONS]({
          params: {
            offset: 0,
            isIncludeTotalCount: true
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getQuotation() {
      try {
        await this[ACTIONS.GET_VIEWS_QUOTATIONS]({
          paramsQuotation: {
            customerId: this.selectCompany.id,
            offset: 0,
            isIncludeTotalCount: true
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    loadMoreQuotations(e) {
      try {
        if (this.noLoadMore) return;
        this.noLoadMore = true;
        const currentRows = this.listQuotation.data.length;
        if (currentRows === this.totalCountQuotation) {
          this.isDisableLoadMore = true;
          return;
        }
        this[ACTIONS.LOAD_MORE_QUOTATIONS]({
          paramsQuotation: {
            isIncludeTotalCount: false,
            offset: currentRows,
            customerId: this.selectCompany.id
          },
          onFinish: async () => {
            e.target.complete();
          }
        });
      } catch (error) {
        this.noLoadMore = true;
      } finally {
        this.noLoadMore = false;
      }
    },
    loadMoreTransactions(e) {
      try {
        if (this.noLoadMoreTrx) return;
        this.noLoadMoreTrx = true;
        if (this.list.data.length === this.totalCount) {
          this.isDisableLoadMoreTrx = true;
          return;
        }
        this[ACTIONS.LOAD_MORE_NOTIFICATION]({
          params: {
            isIncludeTotalCount: false,
            offset: this.list.data.length
          },
          onFinish: async () => {
            e.target.complete();
          }
        });
      } catch (error) {
        this.noLoadMoreTrx = true;
      } finally {
        this.noLoadMoreTrx = false;
      }
    },
    async readNotifications() {
      await this[ACTIONS.GET_ALL_USER_NOTIFICATIONS_B2B]();
      if (this.status) {
        this.getTransaction();
        this.refreshTransactionScreen++;
      }
    },
    openTrxPrice(payload) {
      this.payloadTrxPrice = payload;
      this.setOpenModalTrx(true);
    }
  }
});
</script>
